import React from "react"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"

import InfoBlock from "../components/InfoBlock/InfoBlock"
import Card from "../components/Card/Card"
import LinkButton from "../components/LinkButton/LinkButton"
import SVGIcon from "../components/SVGIcon/SVGIcon"


// Image Asset Imports
import imageYamahaSmall from "../images/home/browser_yamaha_small.png"
import imageUHCSmall from "../images/work/uhc/UHC_DesignSystem_Small.png"
import imageWahlSmall from "../images/home/browser_wahl_small.png"
import imageYamahaLarge from "../images/home/browser_yamaha.png"
import imageUHCLarge from "../images/work/uhc/UHC_DesignSystem.png"
import imageWahlLarge from "../images/home/browser_wahl.png"

const IndexPage = props => (
  <Layout>
    <SEO title="Home" description="I'm Mike. I'm a User Experience Designer and Front-End Developer based out of Milwaukee, Wisconsin." />

    <div className="mt-12 mb-12 sm:mt-24 sm:mb-20">
      <h1 className="mb-10">I’m Mike and I solve digital problems.</h1>
      <p className="mb-4">That’s a fancy way of saying I spend too much time on the internet.</p>
        <p className="mb-10">I work on a design system for <a href="https://www.northwesternmutual.com/" target="_blank" rel="noreferrer">Northwestern Mutual</a> and I'm based out of Milwaukee, Wisconsin.</p>
      <LinkButton
        text="Email Me"
        icon={<SVGIcon icon="mail" height="20" width="20" className="mr-2"/>}
        destination="mailto:mikejhartman@gmail.com"
      />
    </div>

    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16 xl:gap-20 mb-8 md:mb-16">
      <InfoBlock header="Things I do">
        <p className="mb-6">End-to-end user experience design and front-end web development. </p> 
        <p>Whether it’s building a website, designing user experiences, or spearheading a design system, I’ve been there. I've usually made it out in one piece, too!</p>
      </InfoBlock>

      <InfoBlock header="Things I believe">
        <p>Figure out your problems before solving them. Favor systems over single-use solutions <span className="italic">usually</span>, but not <span className="italic">always</span>. Good work comes from communication and collaboration. Always be learning. </p>
        <p>Oh, and be nice to other people. Everyone is fighting their own battle.</p>

      </InfoBlock>
    </div>

    <h2 id="work">Work Samples</h2>
    <p>These are just a few of the fun things I’ve worked on.</p>

    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-x-10 gap-y-20 lg:gap-y-16 mt-8 mb-8">
      <Card
        title={"UnitedHealthcare"}
        image={imageUHCSmall}
        zoomImage={imageUHCLarge}
        imageBGColor="pink"
        link={{ title: "View Case Study", destination: "/work/unitedhealthcare" }}
        subtitle="Web Application & Design System"
      >
        <p>
        Rebuilding a 30-year old legacy system from the ground-up is no small task, nor is developing a design system alongside it. My grey hairs have doubled, but at least you get some fun stories out of it.</p>
      </Card>

      <Card
        title={"Yamaha"}
        image={imageYamahaSmall}
        zoomImage={imageYamahaLarge}
        imageBGColor="orange"
        link={{ title: "View Case Study", destination: "/work/yamaha-outboards"}}
        subtitle="Consumer Website (Agency)"
      >
        <p>
        A media-rich consumer website to excite prospective boaters and provide resources to existing owners. A labor of love made by the most amazing digital team this side of Milwaukee.</p>
      </Card>

      <Card
        title={"Wahl"}
        image={imageWahlSmall}
        zoomImage={imageWahlLarge}
        imageBGColor="green"
        link={{ title: "View Case Study", destination: "/work/wahl" }}
        subtitle="Consumer Website (Agency)"

      >
        <p>
        An eCommerce website unifying three websites into a single, scalable platform. Information architecture was the name of the game, along with building a simple and effective shopping experience.</p>
      </Card>
    </div>

  

  </Layout>


)

export default IndexPage;