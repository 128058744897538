import React from 'react'
import PropTypes from "prop-types"

const InfoBlock = (props) => {

    return (
        <div>
            <h2>{props.header}</h2>
            {props.children}
        </div>
    );
}

InfoBlock.propTypes = {
    children: PropTypes.node.isRequired,
    header: PropTypes.string.isRequired,
}

export default InfoBlock;